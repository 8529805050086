module.exports = {
  first: "#00A0E9",
  firstLight: "#10B0FD",
  firstSuperLight: "#20C0FF",
  firstDark: "#0090C9",
  accent: "#FFB800",
  bright: "#FFF",
  light: "#CCC",
  middle: "#999",
  dark: "#333",
  superDark: "#111",
  red: "#ED0000",
  redLight: "#FD1010",
  green: "#00FF40",
  greenLight: "#10FF50"
};
