let auth = null;
let db = null;
let firebase = null;

/* Hack: this is required for gatsby server-side rendering to work properly */
if (typeof window !== `undefined`) {
  auth = require("./auth");
  db = require("./db");
  firebase = require("./firebase");
}

export {
  auth,
  db,
  firebase,
};

