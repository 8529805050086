import { auth, serverTimestamp, db } from './firebase';

export const getCurrentUserEmail = () => auth.currentUser ? auth.currentUser.email : '';
export const getCurrentUserName = () => auth.currentUser ? auth.currentUser.displayName.toLowerCase() : '';
export const getCurrentUserDisplayName = () => auth.currentUser ? auth.currentUser.displayName : '';
export const getCurrentUserPhotoUrl = () => auth.currentUser ? auth.currentUser.photoURL : '';

export const getVersionSnapshot = () => {
  const languageRef = db.collection('languages').doc('sv');
  return languageRef;
}

export const getUserPromise = (userId = null) => {
  if (userId) {
    return db.collection("users").doc(userId).get();
  } else {
    return db.collection("users").doc(getCurrentUserEmail()).get();
  }
}

export const getUser = (userId = null) => {
  return getUserPromise(userId).then(user => {
    if (user == null || !user.exists) {
      throw new Error("User doesn't exist");
    }

    const dbUser = user.data();
    console.log("User:", dbUser.displayName);
    return {
      id: user.id,
      name: dbUser.displayName,
      email: dbUser.email,
      currentProgram: dbUser.currentProgram || 0
    }
  });
}

export const getPractitioner = (userId = null) => {
  return getUserPromise().then(practitioner => {
    if (practitioner == null || !practitioner.exists) {
      throw new Error("Practitioner doesn't exist");
    }

    const dbPractitioner = practitioner.data();
    console.log("Practitioner:", practitioner.ref.path);
    console.log("Practitioner level: ", dbPractitioner.level);

    return {
      id: practitioner.id,
      name: dbPractitioner.displayName,
      email: dbPractitioner.email,
      level: dbPractitioner.level,
      clinic: dbPractitioner.clinic,
      agreementDate: dbPractitioner.agreementDate && dbPractitioner.agreementDate.toDate()
    }
  });
}

export const getProgram = (userId, programId) => {
  const userRef = db.collection("users").doc(userId);
  const programsRef = userRef.collection("programs").doc(programId.toString())
  return programsRef.get().then(program => {
    const dbProgram = program.data();
    return {
      id: program.id,
      name: dbProgram.name || `Program #${program.id}`,
      message: dbProgram.message,
      published: dbProgram.published,
      sent: dbProgram.sent
    }
  });
}

//Used in Run, functions?
export const getExercisesPromise = programNumber => {
  const exercisesRef = db.collection("users").doc(getCurrentUserEmail()).collection("programs").doc(programNumber.toString()).collection('exercises').orderBy('ordinal');
  return exercisesRef.get();
}

export const getInstructions = practitionerLevel => {
  if (practitionerLevel < 100) {
    return [];
  }
  //FIXME: Language should not be hard coded
  const instructionsRef = db.collection("languages").doc("sv").collection("instructions").where("level", "<=", practitionerLevel);
  return instructionsRef.orderBy("level").orderBy("name").get().then(query => {
    const allInstructions = query.docs.map(instructionsRef => {
      const instruction = instructionsRef.data();
      return {
        id: instructionsRef.id,
        name: instruction.name,
        description: instructionsRef.id,
        shortText: instruction.shortText,
        text: instruction.text,
        videoId: instruction.videoId,
        repeatUnit: instruction.repeatUnit,
        repeat: instruction.repeat,
        sets: instruction.sets,
        steps: instruction.steps,
        ordinal: instruction.ordinal,
        about: instruction.about,
        affects: instruction.affects
      };
    });
    const sortedInstructions = allInstructions.sort((a, b) => a.name.localeCompare(b.name));
    return sortedInstructions;
  })
}

export const getUsers = (practitioner) => {
  if (practitioner.level < 100) {
    return [];
  }
  const usersRef = db.collection("users");
  return usersRef.where("clinic", "==", practitioner.clinic).where("deleted", "==", false).orderBy("modified", "desc").limit(500).get().then(query => {
    const allUsers = query.docs.map(usersRef => {
      const user = usersRef.data();
      return {
        id: usersRef.id,
        name: user.displayName || usersRef.id,
        description: usersRef.id
      };
    });          
    return allUsers;
  });
}

export const getPrograms = (userId, forTemplates = false) => {
  const userRef = db.collection('users').doc(userId.toString());
  const programsRef = userRef.collection('programs');
  return programsRef.orderBy('created', 'desc').limit(500).get().then(query => {
    const allPrograms = query.docs.map(programRef => {
      return mapProgram(userId, programRef, forTemplates);
    });
    return allPrograms;
  });
}

export const mapProgram = (userId, programRef, forTemplates = false) => {
  const program = programRef.data();
  const createdDate = program.created ? program.created.toDate().toLocaleString('sv-SE', {year: 'numeric', month: 'numeric', day: 'numeric'}) : '';
  const status = forTemplates ? '' : (program.sent ? 'Skickat' : (program.published ? 'Skickas' : 'Utkast'));
  const description = createdDate;
  return {
    id: userId + programRef.id,
    originalId: programRef.id,
    name: program.name || `Program #${programRef.id}`,
    description: forTemplates ? 'Mall' : description,
    message: program.message,
    published: program.published,
    sent: program.sent,
    canDelete: !forTemplates && !program.sent,
    deleted: program.deleted,
    status,
    userId: userId
  };
}

export const getProgramsSnapshot = (userId) => {
  const userRef = db.collection('users').doc(userId.toString());
  const programsRef = userRef.collection('programs');
  return programsRef.orderBy('created', 'desc').limit(500);
}

export const getExercises = (userId, programId) => {
  const userRef = db.collection("users").doc(userId.toString());
  const programRef = userRef.collection("programs").doc(programId.toString());
  const exercisesRef = programRef.collection('exercises');
  return exercisesRef.orderBy('ordinal').get().then(query => {
    const allExercises = query.docs.map(exerciseRef => {
      return mapExercise(exerciseRef);
    });
    return allExercises;
  });
}

export const mapExercise = (exerciseRef) => {
  const exercise = exerciseRef.data();
  return {
    id: exerciseRef.id,
    name: exercise.name,
    ordinal: exercise.ordinal,
    instructionRef: exercise.instructionRef,
    videoId: exercise.videoId,
    repeatUnit: exercise.repeatUnit,
    repeat: exercise.repeat,
    sets: exercise.sets,
    steps: exercise.steps,
    grouped: exercise.grouped,
    groupedFirst: exercise.groupedFirst,
    shortTrack: exercise.shortTrack,
    multiTrack: exercise.multiTrack,
    about: exercise.about
  }
}

export const getExercisesSnapshot = (userId, programId) => {
  const userRef = db.collection("users").doc(userId.toString());
  const programRef = userRef.collection("programs").doc(programId.toString());
  const exercisesRef = programRef.collection('exercises');
  return exercisesRef.orderBy('ordinal');
}

export const createProgram = (userId, userDisplayName, currentProgram, programName = null, programMessage = null) => {
  const userRef = db.collection("users").doc(userId);
  const newProgram = currentProgram + 1;
  const programRef = userRef.collection("programs").doc(newProgram.toString());
  return userRef.set({
    currentProgram: newProgram,
    modified: serverTimestamp,
    modifiedBy: getCurrentUserEmail(),
  }, { merge: true }).then(() => {
    const program = {
      name: programName || `Program #${newProgram}`,
      message: programMessage,
      published: false,
      sent: false,
      created: serverTimestamp,
      createdBy: getCurrentUserEmail()
    };
    programRef.set(program);
    program.id = newProgram;
    return program;
  }).catch((error) => {
    console.log(`Error creating program ${newProgram} "${programName}": ${error}`);
  });
}

export const deleteProgram = (practitioner, userId, programId) => {
  console.log("PractitionerId:", practitioner.id, "UserId:", userId, "ProgramId:", programId);
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(programId.toString());
  return programRef.set({
    deleted: true,
    modified: serverTimestamp,
    modifiedBy: practitioner.id
  }, { merge: true }).then(() => {
    return {
      id: programId
    };
  }).catch((error) => {
    console.log(`Error deleting program ${programId} for ${userId}: ${error}`);
  });
}

export const addExercise = (userId, currentProgram, exercise, exerciseNumber) => {
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(currentProgram.toString());
  const exercisesRef = programRef.collection("exercises");
  let instructionRef = null;
  
  if (exercise.instructionId) {
    //FIXME: SV should not be hard coded
    instructionRef = db.collection("languages").doc("sv").collection("instructions").doc(exercise.instructionId);
  } else {
    /* Security rules demand exercises match their instructions */
    instructionRef = exercise.instructionRef;
    exercise.instructionRef.get().then(instructionRef => {
      const instruction = instructionRef.data();
      exercise.name = instruction.name;
      exercise.steps = instruction.steps;
      exercise.videoId = instruction.videoId;
      exercise.about = instruction.about;
      exercise.repeatUnit = instruction.repeatUnit;
    })
  }

  exercisesRef.doc(exercise.id.toString()).set({
    ordinal: exerciseNumber,
    instructionRef: instructionRef,
    name: exercise.name,
    shortText: exercise.shortText || '',
    text: exercise.text || '',
    videoId: exercise.videoId,
    repeat: Number(exercise.repeat),
    repeatUnit: exercise.repeatUnit,
    multiTrack: exercise.multiTrack,
    shortTrack: exercise.shortTrack,
    sets: Number(exercise.sets),
    grouped: exercise.grouped,
    groupedFirst: exercise.groupedFirst,
    steps: exercise.steps,
    about: exercise.about || ''
  }).then(() => {
    console.log(`Added exercise ${exercise.id} "${exercise.name}"`);
  }).catch((error) => {
    console.log(`Error adding exercise ${exercise.id} "${exercise.name}": ${error}`);
  });
}

export const updateExercise = (userId, currentProgram, exercise) => {
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(currentProgram.toString());
  const exerciseRef = programRef.collection("exercises").doc(exercise.id.toString());
  const modifiedExercise = {
    repeat: Number(exercise.repeat) || 1,
    multiTrack: exercise.multiTrack,
    shortTrack: exercise.shortTrack,
    sets: Number(exercise.sets) || 1,
    grouped: exercise.grouped,
    groupedFirst: exercise.groupedFirst
  }
  exerciseRef.set(modifiedExercise, { merge: true }).then(() => {
    console.log(`Updated exercise ${exercise.id} "${exercise.name}"`);
  }).catch(error => {
    console.log(`Error updating exercise ${exercise.id} "${exercise.name}": ${error}`);
  });
}

export const moveExercise = (userId, currentProgram, exerciseId, ordinal) => {
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(currentProgram.toString());
  const exerciseRef = programRef.collection("exercises").doc(exerciseId.toString());
  exerciseRef.set({
    ordinal: Number(ordinal)
  }, { merge: true }).then(() => {
    console.log(`Moved exercise ${exerciseId} to position ${ordinal}`);
  });
}

export const removeExercise = (userId, currentProgram, exercise) => {
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(currentProgram.toString());
  const exerciseRef = programRef.collection("exercises").doc(exercise.id.toString());
  exerciseRef.delete().then(() => {
    console.log(`Removed exercise ${exercise.id} "${exercise.name}"`);
  });
}

export const publishProgram = (userId, currentProgram, programName, message, dontSendToCustomer = false) => {
  const userRef = db.collection("users").doc(userId);
  const programRef = userRef.collection("programs").doc(Number(currentProgram || 0).toString());
  programRef.set({
    name: programName,
    message: message,
    dontSendToCustomer: dontSendToCustomer,
    published: true,
    sent: false
  }, { merge: true }).then(() => {
    console.log(`Published program ${currentProgram} "${programName}"`);
  }).catch((error) => {
    console.log(`Error publishing program: ${currentProgram} "${programName}": ${error}`);
  });
}

export const addSelf = () => {
  const userId = getCurrentUserEmail();
  const userRef = db.collection("users").doc(userId);
  return userRef.set({
    clinic: null,
    modified: serverTimestamp,
    modifiedBy: userId,
    created: serverTimestamp,
    createdBy: userId,
    currentProgram: null,
    level: 0,
    paidUntil: new Date(new Date().getTime()+(7*24*60*60*1000)),
    name: auth.currentUser.displayName.toLowerCase(),
    displayName: auth.currentUser.displayName,
    photoUrl: auth.currentUser.photoURL,
    deleted: false
  });
}

export const getOrCreateUser = (practitioner, userId, userDisplayName) => {
  const userRef = db.collection("users").doc(userId);
  return userRef.get().then(user => {
    console.log("Loading user");
    const dbUser = user.data();
    return {
      id: user.id,
      name: dbUser.displayName || user.id,
      description: userRef.id
    };
  }).then(user => {
    console.log("Recovering user");
    return userRef.set({
      modified: serverTimestamp,
      modifiedBy: practitioner.id,
      deleted: false
    }, { merge: true }).then(() => {
      return user;
    });
  }).catch(error => {
    console.log("Creating new user");
    return userRef.set({
      clinic: practitioner.clinic,
      modified: serverTimestamp,
      modifiedBy: practitioner.id,
      created: serverTimestamp,
      createdBy: practitioner.id,
      currentProgram: null,
      level: 0,
      paidUntil: new Date(new Date().getTime()+(7*24*60*60*1000)),
      name: userDisplayName.toLowerCase(),
      displayName: userDisplayName || userId,
      deleted: false
    }).then(() => {
      return {
        id: userId,
        name: userDisplayName
      };
    }).catch(error => {
      console.log(`Failed to create user ${userDisplayName} (${userId}): ${error}`);
    });
  });
}

export const updateUser = (practitioner, userId, userDisplayName, email) => {
  const userRef = db.collection("users").doc(userId);
  return userRef.set({
    name: userDisplayName.toLowerCase(),
    displayName: userDisplayName,
    email,
    modified: serverTimestamp,
    modifiedBy: practitioner.id
  }, { merge: true }).then(() => {
    return {
      id: userId,
      name: userDisplayName,
      email
    };
  }).catch((error) => {
    console.log(`Error updating user ${userId} "${userDisplayName}": ${error}`);
  });
}

export const deleteUser = (practitioner, userId) => {
  const userRef = db.collection("users").doc(userId);
  return userRef.set({
    deleted: true,
    modified: serverTimestamp,
    modifiedBy: practitioner.id
  }, { merge: true }).then(() => {
    return {
      id: userId
    };
  });
}

export const acceptAgreement = (practitioner, agreementDate) => {
  const userRef = db.collection("users").doc(practitioner.id);
  return userRef.set({
    agreementDate: agreementDate,
    modified: serverTimestamp,
    modifiedBy: practitioner.id
  }, { merge: true }).then(() => {
    return {
      id: practitioner.id
    };
  });
}

// TODO: Is this right? Data is written each time!
export const setMissingUserProperties = user => {
  if (user.name === getCurrentUserName()
    && user.displayName === getCurrentUserDisplayName()
    && user.photoUrl === getCurrentUserPhotoUrl()
  ) { return null; }

  const userRef = db.collection("users").doc(user.id);
  userRef.set({ 
    name: getCurrentUserName(),
    displayName: getCurrentUserDisplayName(),
    photoUrl: getCurrentUserPhotoUrl(),
    modified: serverTimestamp,
    modifiedBy: user.id
  }, { merge: true })
  return user;
}
