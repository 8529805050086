import React from 'react';
import { Link } from "gatsby"
import Img from "gatsby-image";
import styled from "styled-components";
import colors from '../../styles/colors';
import previous from '../../images/back.svg';
import edit from '../../images/edit.svg';

const LayoutContainer = styled.div`
  max-width: 834px;
  height: 100%;
  margin: 0 auto;
  background: ${colors.bright};
  background-image: radial-gradient(#f0f0f0, ${colors.bright});
  color: ${colors.dark};
`;

const Header = styled.div`
  display: block;
  position: fixed;
  max-width: 834px;
  width: 100%;
  height: 5rem;
  background-image: linear-gradient(to bottom, white 90%, rgba(255,255,255,.1) 100%);
  color: ${colors.dark};
  padding-top: 1.2rem;
  opacity: 1;
  z-index: 1;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.4;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding: 0 1rem;
`;

const SubHeading = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 1.5rem;
  margin-right: 1rem;
  margin-top: 1rem;
`;

const SubHeadingText = styled.div`
  width: 100%;
  line-height: 2rem;
  margin-right: 0.25rem;
  padding-left: .5rem;
  padding-right: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: default;
`;

const FirstWord = styled.span`
  font-weight: 700;
`;

const Text = styled.div`
  opacity: ${props => props.hide ? '0' : '1'};
  transition: opacity .25s ease-in;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${props => props.hide ? '0' : '1'};
  transition: opacity .25s ease-in;
`;

const ItemIcon = styled.img`
  height: .6rem;
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  color: ${colors.dark};
`;

const List = styled.ul`
  margin: 0rem 1rem;
  font-size: .75rem;
  padding-top: 6rem;
  padding-bottom: 5rem;
`;

const ListItem = styled.li`
  line-height: 1.5;
  margin-top: .5rem;
  max-width: 400px;
`;

const MainActionContainer = styled.div`
  position: fixed;
  bottom: 1.25rem;
  width: 100%;
  max-width: 834px;
`;

const Margin = styled.div`
  margin: 0 1rem;
`;

const ButtonList = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${props => props.spaceBetween ? 'space-between' : 'center'};
  align-items: center;
`;

const Image = styled(Img)`
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

const LayoutContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Layout = (props) => {
  if (props.image) {
    return (
      <LayoutContainer>
        <Image fluid={props.image.node.childImageSharp.fluid} />
        <LayoutContent>
          {props.children}
        </LayoutContent>
      </LayoutContainer>
    );
  } else {
    return (
      <LayoutContainer>
        {props.children}
      </LayoutContainer>
    );
  }
};

const MainAction = (props) => {
  return (
    <MainActionContainer>
      <Margin>
        <ButtonList spaceBetween={props.spaceBetween}>
          {props.children}
        </ButtonList>
      </Margin>
    </MainActionContainer>
  );
};

const Heading = (props) => {
  return (
    <HeadingContainer>
      { props.backLink != null ?
        <div><Link to={props.backLink}><IconButton icon={previous} /></Link></div>
      :
        props.backClick != null ?
          <div><IconButton onClick={props.backClick} icon={previous} /></div>
        :
        <span>&nbsp;</span>
      }

      { props.multiLine ?
        <Text hide={props.children === null}>{props.children}</Text>
      :
        <EllipsisText hide={props.children === null}>{props.children}</EllipsisText>
      }

      { props.editLink != null ?
        <div>&nbsp;<Link to={props.editLink}><IconButton icon={edit} /></Link></div>
      :
        props.editClick != null ?
          <div>&nbsp;<IconButton onClick={props.editClick} icon={edit} /></div>
        :
          <span>&nbsp;</span>
      }
    </HeadingContainer>
  );
};

const IconButton = (props) => {
  return (
    <ItemIcon src={props.icon} alt="" onClick={props.onClick} />
  );
}

export {
  Layout,
  Header,
  Heading,
  SubHeading,
  SubHeadingText,
  FirstWord,
  Content,
  List,
  ListItem,
  MainAction
}
