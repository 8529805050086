import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const prodConfig = {
  apiKey: "AIzaSyC377jWJJKRSdVmCwfWHMhL6rnqvCz-mL4",
  authDomain: "optimum-online.firebaseapp.com",
  databaseURL: "https://optimum-online.firebaseio.com",
  projectId: "optimum-online",
  storageBucket: "",
  messagingSenderId: "635953993536"
};

const devConfig = {
  apiKey: "AIzaSyC377jWJJKRSdVmCwfWHMhL6rnqvCz-mL4",
  authDomain: "optimum-online.firebaseapp.com",
  databaseURL: "https://optimum-online.firebaseio.com",
  projectId: "optimum-online",
  storageBucket: "",
  messagingSenderId: "635953993536"
};

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.firestore();
const dbSettings = {timestampsInSnapshots: true};
db.settings(dbSettings);

const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export {
  db,
  serverTimestamp,
  auth,
  googleProvider
};
